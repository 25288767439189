import React from 'react';

import placeholderIcon from 'assets/icons/colored/placeholder-no-photo-mini.svg';
import placeholder from 'assets/icons/colored/placeholder-no-photo.svg';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {interpolate} from 'client/ducks/language/helpers';
import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import ImageField from 'client/common/fields/image-field';
import TextField from 'client/common/fields/text-field';
import TextareaField from 'client/common/fields/textarea-field';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {LotteryPrizeModalFormName} from 'client/components/lottery/modals/lottery-prize-modal';

import cssModule from './win-params-fieldset.module.scss';

const b = bem('win-params-fieldset', {cssModule});

type WinParamsFieldsetProps = {
  disabled: boolean;
  onRemoveImage?: (field: string) => void;
};

const PICTO_SIZE = 256;

const WinParamsFieldset: React.FC<WinParamsFieldsetProps> = (props) => {
  const {disabled, onRemoveImage} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL.WIN_PARAMS_FIELDSET');
  const languages = useSelector(selectPrizeLanguages);

  const languageOptions = languages.map((i) => ({value: i, label: i}));

  const {
    formValues: {winParams = {}},
    change,
  } = useReduxForm(LotteryPrizeModalFormName);
  const {language, defaultLanguage, langUniqueMode} = winParams;

  const copyDefaultValue = (field: string, newValue?: string | File) => {
    if (langUniqueMode) {
      for (const key of languages) {
        change(`winParams.${field}.${key}`, newValue);
      }
    } else if (language === defaultLanguage) {
      const fieldValues = winParams[field];
      const defaultValue = newValue || fieldValues[defaultLanguage];

      if (defaultValue) {
        for (const [key, value] of Object.entries(fieldValues)) {
          if (key !== defaultLanguage && !value) {
            change(`winParams.${field}.${key}`, defaultValue);
          }
        }
      }
    }
  };

  const onUpload = (field: string, file: File) => {
    change(`winParams.${field}.${language}`, file);
    copyDefaultValue(field, file);
  };

  return (
    <div className={b()}>
      {typeof langUniqueMode === 'boolean' && (
        <LanguageDevicePanel
          className={b('lang-group')}
          languages={languageOptions}
          language={language}
          onChangeLanguage={(value) => change('winParams.language', value)}
          langInitiallyCommon={langUniqueMode}
          onLanguageCommonToggle={(value) => change('winParams.langUniqueMode', value)}
          prefix="win-params-language"
        />
      )}
      <div className={b('field')}>
        <TextField
          className={b('name-field')}
          name={`winParams.name.${language}`}
          label={lang.PRIZE_NAME}
          onBlur={() => copyDefaultValue('name')}
          disabled={disabled}
          withWrap={true}
          required={true}
        />
        <ImageField
          disabled={disabled}
          className={b('picto-field')}
          name={`winParams.picto.${language}`}
          onChange={(file: File) => onUpload('picto', file)}
          acceptFormats=".png,.jpg,.jpeg"
          placeholderIcon={placeholderIcon}
          placeholders={{
            noValue: lang.PLACEHOLDER_PICTO_INSERT,
            withValue: lang.PLACEHOLDER_PICTO_REPLACE,
          }}
          checks={{
            checks: {
              check_error_size: PICTO_SIZE,
            },
            messages: {
              check_error_size: interpolate(lang.PICTO_SIZE_ERROR?.toString(), {
                width: PICTO_SIZE,
                height: PICTO_SIZE,
              }),
            },
          }}
          onRemove={() => onRemoveImage?.(`picto_${language}`)}
          removable={false} // TODO: https://weezio-dev.atlassian.net/browse/WEEZIO-3139
        />
      </div>
      <div className={b('image-row')}>
        <ImageField
          classNames={{
            controls: b('win-params-controls'),
          }}
          name={`winParams.image.${language}`}
          label={lang.IMAGE}
          onChange={(file: File) => onUpload('image', file)}
          acceptFormats=".png,.jpg,.jpeg"
          placeholderIcon={placeholder}
          keepRatio={true}
          disabled={disabled}
          removable={false} // TODO: https://weezio-dev.atlassian.net/browse/WEEZIO-3139
        />
      </div>
      <TextareaField
        name={`winParams.description.${language}`}
        label={lang.DESCRIPTION}
        textareaClassName={b('description')}
        onBlur={() => copyDefaultValue('description')}
        disabled={disabled}
      />
      <TextareaField
        name={`winParams.legal.${language}`}
        className={b('legal-field')}
        label={lang.LEGAL}
        onBlur={() => copyDefaultValue('legal')}
        height={75}
        disabled={disabled}
      />
    </div>
  );
};

export default WinParamsFieldset;
