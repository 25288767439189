import {PRIZE_TYPES} from 'client/components/prizes/constants';
import mapCouponFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-fieldset/mapFormValues';
import mapCouponImportsFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-imports-fieldset/mapFormValues';
import mapEmailFieldset from 'client/components/prizes/fieldsets/prize-diy-email-fieldset/mapFormValues';
import mapInstantWinFieldset from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset/mapFormValues';
import mapMainFieldset from 'client/components/prizes/fieldsets/prize-diy-main-fieldset/mapFormValues';
import mapSegmentation from 'client/components/prizes/fieldsets/prize-diy-segmentation-fieldset/mapFormValues';
import mapTicketFieldset from 'client/components/prizes/fieldsets/prize-diy-ticket-fieldset/mapFormValues';
import mapWinFieldset from 'client/components/prizes/fieldsets/prize-diy-win-fieldset/mapFormValues';

export default async (values) => {
  const email = await mapEmailFieldset(values.email, values.main.email_send);
  const ticket = await mapTicketFieldset(values.ticket, values.main.create_ticket);
  const winPopup = await mapWinFieldset(values.win_pop_up);
  const main = await mapMainFieldset(values.main);
  const coupon =
    values.main.type === PRIZE_TYPES.COUPON
      ? {
          coupon: {
            ...mapCouponFieldset(values.coupon).coupon,
            ...mapCouponImportsFieldset(values.coupon.imports, values.customization?.geo_level),
            test_mode: values.coupon?.test_mode || false,
          },
        }
      : {};

  return {
    ...main,
    ...mapInstantWinFieldset(values),
    ...mapSegmentation(values.segmentation),
    ...email,
    ...ticket,
    ...winPopup,
    ...coupon,
  };
};
