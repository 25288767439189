export const PRIZE_TYPES = {
  COUPON: 'CouponPrize',
  LOYALTY: 'LoyaltyPrize',
  COMMON: 'Prize',
};

export const PRIZE_COUPON_VALIDITY_TYPES = {
  FIXED_VALUE: 'fixed_value',
  DURATION: 'duration',
};

export const PRIZE_CODE_TYPES = {
  QR_CODE: 'qr_code',
  BAR_CODE: 'barcode',
  PLAIN_TEXT: 'plain_text',
  NONE: 'none',
};

export const PRIZE_BARCODE_FORMAT = {
  EAN_8: 'ean8',
  EAN_13: 'ean13',
  CODE_128: 'code128',
  CODE_39: 'code39',
  NONE: 'none',
};

export const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const SEGMENTATION_TYPES = {
  NONE: 'none',
  PER_DEVICE: 'device',
  PER_CAMPAIGN: 'campaign',
  PER_CHANNEL: 'source',
  GLOBAL: 'global',
};

export const TIME_FORMAT = 'HH:mm:ss';

export const COMMON_INPUT = 'common';

export const LOYALTY_COUPON_TYPES = {
  NONE: 'none',
  OPTIONAL: 'optional',
  MANDATORY: 'mandatory',
};

export const LOYALTY_VALUE_UNITS = {
  POINTS: 'points',
  EURO: 'euro',
};
