import React from 'react';

import {get} from 'lodash';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useReduxForm} from 'client/services/hooks';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import cssModule from './general-language-selector.module.scss';

const b = bem('general-language-selector', {cssModule});

type GeneralLanguageSelectorProps = {
  langUniqueMode: boolean;
};

const GeneralLanguageSelector: React.FC<GeneralLanguageSelectorProps> = (props) => {
  const {langUniqueMode} = props;
  const languages = useSelector(selectPrizeLanguages);

  const languageOptions = languages.map((i) => ({value: i, label: i}));

  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);
  const {language, defaultLanguage} = formValues?.main || {};

  const handleLanguageCommonToggle = (isCommon: boolean) => {
    // copy the value of field for defaultLanguage to all other language fields
    if (isCommon) {
      const email = {...formValues?.email};
      const ticket = {...formValues?.ticket};
      const win: Record<string, string> = {};
      const prizeNames: Record<string, string> = {};
      const prizePictos: Record<string, string> = {};
      const legal: Record<string, string> = {};
      languages.forEach((langKey) => {
        // email
        email[`email_image_${langKey}`] = get(formValues, `email.email_image_${defaultLanguage}`);
        email[`external_description_${langKey}`] = get(formValues, `email.external_description_${defaultLanguage}`);

        // ticket
        ticket[`ticket_image_${langKey}`] = get(formValues, `ticket.ticket_image_${defaultLanguage}`);

        // win pop-up
        win[`win_pop_up_image_${langKey}`] = get(formValues, `win_pop_up.win_pop_up_image_${defaultLanguage}`);

        // prize name
        prizeNames[langKey] = get(formValues, `main.prize_names.${defaultLanguage}`);
        prizePictos[langKey] = get(formValues, `main.prize_pictos.${defaultLanguage}`);

        // legal
        legal[langKey] = get(formValues, `main.legal.${defaultLanguage}`);
      });
      change('email', email);
      change('ticket', ticket);
      change('win_pop_up', win);
      change('main.prize_names', prizeNames);
      change('main.prize_pictos', prizePictos);
      change('main.legal', legal);
    }

    change('main.language', isCommon ? COMMON_INPUT : defaultLanguage);
  };

  return (
    <LanguageDevicePanel
      className={b()}
      languages={languageOptions}
      language={language}
      onChangeLanguage={(value) => change('main.language', value)}
      langInitiallyCommon={langUniqueMode}
      onLanguageCommonToggle={handleLanguageCommonToggle}
      prefix="main-language"
    />
  );
};

export default GeneralLanguageSelector;
