import {isEmptyString, isInvalidDate, isFromLessThenTo} from 'client/services/validator';

import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';
import {Translation} from 'client/models/language/types';

import {ValidityValues, ValidityErrors} from './types';

export default (values: ValidityValues, lang: Record<string, Translation>) => {
  const errors: ValidityErrors = {};

  if (values.validity_type === PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE) {
    if (isEmptyString(values.valid_from)) {
      errors.valid_from = lang.REQUIRED;
    } else if (isInvalidDate(values.valid_from)) {
      errors.valid_from = lang.INVALID_DATE;
    }

    if (isEmptyString(values.valid_to)) {
      errors.valid_to = lang.REQUIRED;
    } else if (isInvalidDate(values.valid_to)) {
      errors.valid_to = lang.INVALID_DATE;
    }

    if (!errors.valid_from && !errors.valid_to && isFromLessThenTo(values.valid_from, values.valid_to)) {
      errors.valid_from = lang.BEFORE_TO;
    }
  }

  if (values.validity_type === PRIZE_COUPON_VALIDITY_TYPES.DURATION) {
    if (isEmptyString(values.validity_days)) {
      errors.validity_days = lang.REQUIRED;
    } else if (Number(values.validity_days) < 1) {
      errors.validity_days = lang.POSITIVE_INTEGER;
    }
  }

  return errors;
};
