import {blobToBase64} from 'client/services/blobToBase64';
import {prettyJoin} from 'client/services/helpers';

import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PRIZE_TYPES} from 'client/components/prizes/constants';
import mapValidityValues from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/mapFormValues';

const mapMediaTranslations = async (values, fieldName, sendName) => {
  const result = {};

  for (const key of Object.keys(values[fieldName])) {
    if (typeof values[fieldName][key] === 'string') {
      continue;
    }
    result[prettyJoin([sendName, key], '_')] = {
      data: await blobToBase64(values[fieldName][key]),
    };
  }
  return result;
};

export default async (values) => {
  const external_name = values.prize_names[values.language] || Object.values(values.prize_names).filter((i) => i)[0];

  const externalNameTranslations = Object.keys(values.prize_names).reduce(
    (result, key) => ({
      ...result,
      [`external_name_${key}`]: values.prize_names[key],
    }),
    {},
  );

  const pictoTranslations = await mapMediaTranslations(values, 'prize_pictos', 'picto');

  const legalTranslations = Object.keys(values.legal).reduce(
    (result, key) => ({
      ...result,
      [`legal_${key}`]: values.legal[key],
    }),
    {},
  );

  return {
    type: values.type === PRIZE_TYPES.COMMON ? null : values.type,
    email_send: values.email_send,
    sms_send: values.sms_send,
    ticket_print: values.create_ticket,
    external_name,
    internal_name: values.internal_name || external_name,
    loyalty_coupon_type: values.loyalty_coupon_type,
    level: values.level,
    distribution: values.distribution,
    loyalty_only: values.loyalty_only,
    language_unique_mode: values.language === COMMON_INPUT,
    ...pictoTranslations,
    ...externalNameTranslations,
    ...legalTranslations,
    ...(values.type === PRIZE_TYPES.LOYALTY && {
      loyalty_unit: values.loyalty_unit,
      loyalty_amount: values.loyalty_amount,
    }),
    ...mapValidityValues(values),
  };
};
