import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getPrizeNames(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PARTICIPATION_PRIZES}/available_prize_names?automation_task_id=${id}`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_PARTICIPATION_PRIZES_REQUEST,
        types.GET_PARTICIPATION_PRIZES_SUCCESS,
        types.GET_PARTICIPATION_PRIZES_ERROR,
      ],
      queryParams,
    });
}

export function updatePrizeValidation(id, body = {}, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}/participation_prizes/${id}`,
      body: JSON.stringify(body),
      method: 'PUT',
      noCache: true,
      types: [
        types.GET_PARTICIPATION_PRIZES_REQUEST,
        types.GET_PARTICIPATION_PRIZES_SUCCESS,
        types.GET_PARTICIPATION_PRIZES_ERROR,
      ],
      queryParams,
    });
}

export const createDiyPrize = (body) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PRIZES}`,
        method: 'POST',
        types: [types.CREATE_DIY_PRIZE_REQUEST, types.CREATE_DIY_PRIZE_SUCCESS, types.CREATE_DIY_PRIZE_ERROR],
        noCache: true,
        body,
      },
      true,
    );
};

export const updateDiyPrize = (prizeId, body) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PRIZES}/${prizeId}`,
        method: 'PATCH',
        types: [types.UPDATE_DIY_PRIZE_REQUEST, types.UPDATE_DIY_PRIZE_SUCCESS, types.UPDATE_DIY_PRIZE_ERROR],
        noCache: true,
        body,
      },
      true,
    );
};

export const deleteDiyPrize = (prizeId) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PRIZES}/${prizeId}`,
        method: 'DELETE',
        types: [types.DELETE_DIY_PRIZE_REQUEST, types.DELETE_DIY_PRIZE_SUCCESS, types.DELETE_DIY_PRIZE_ERROR],
      },
      true,
    );
};

export const deleteDiyPrizeCoupon = (couponId) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.COUPONS}/${couponId}`,
        method: 'DELETE',
        types: [
          types.DELETE_DIY_PRIZE_COUPON_REQUEST,
          types.DELETE_DIY_PRIZE_COUPON_SUCCESS,
          types.DELETE_DIY_PRIZE_COUPON_ERROR,
        ],
      },
      true,
    );
};

export const getPrizeMapsByLevel = ({queryParams, level, sourceIds}) => {
  let queryLevel = '';
  const sourceIdsKey = level === 'DEVICE' ? 'geo_source_id_in' : 'online_source_id_in';
  let queryLevelValue = '';
  if (['DEVICE_GLOBAL', 'DEVICE'].includes(level)) {
    queryLevel = 'geo_level_eq';
  } else {
    queryLevel = 'online_level_eq';
  }
  if (['DEVICE_GLOBAL', 'ONLINE_GLOBAL'].includes(level)) {
    queryLevelValue = 'global';
  } else if (level === 'CHANNEL') {
    queryLevelValue = 'source';
  } else {
    queryLevelValue = level.toLowerCase();
  }

  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PRIZE_MAPS}`,
        method: 'GET',
        types: [
          types.GET_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST,
          types.GET_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS,
          types.GET_DIY_PRIZE_MAPS_BY_LEVEL_ERROR,
        ],
        queryParams: {
          ...queryParams,
          q: {
            ...queryParams.q,
            [queryLevel]: queryLevelValue,
            [sourceIdsKey]: sourceIds,
          },
        },
      },
      true,
    );
};

export const deleteDiyPrizeImage = (prizeId, imageId) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.REMOVE_IMAGES}`,
        method: 'PATCH',
        types: [
          types.DELETE_DIY_PRIZE_IMAGE_REQUEST,
          types.DELETE_DIY_PRIZE_IMAGE_SUCCESS,
          types.DELETE_DIY_PRIZE_IMAGE_ERROR,
        ],
        queryParams: {
          record_type: 'Prize',
          record_id: prizeId,
          image_ids: [imageId],
        },
      },
      true,
    );
};
