import moment from 'moment';

import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';

import {ValidityValues} from './types';

export default (values: ValidityValues) => {
  const isDuration = values.validity_type === PRIZE_COUPON_VALIDITY_TYPES.DURATION;

  const result: Record<string, any> = {validity_duration: isDuration};

  if (isDuration) {
    result.validity_days = values.validity_days;
  } else {
    result.validity_from = moment(values.valid_from, 'DD/MM/YYYY').toISOString();
    result.validity_to = moment(values.valid_to, 'DD/MM/YYYY').toISOString();
  }

  return result;
};
