import React from 'react';

import placeholderIcon from 'assets/icons/colored/placeholder-ticket.svg';
import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {ImageField, SelectField} from 'client/common/fields';
import Icon from 'client/common/icon';

// import {DropdownField} from 'client/components/common/fields';
import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PRIZE_CODE_TYPES} from 'client/components/prizes/constants';
import {getTypeCodes} from 'client/components/prizes/helpers';
import PrizeDiyModal from 'client/components/prizes/modals/prize-diy-modal';

import './prize-diy-ticket-fieldset.scss';

const b = bem('prize-diy-ticket-fieldset');

const PrizeDiyTicketFieldset = (props) => {
  const {disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_TICKET_FIELDSET');
  const {formValues, change} = useReduxForm(PrizeDiyModal.formName);
  const {language, defaultLanguage} = formValues?.main || {};
  const languages = useSelector(selectPrizeLanguages);
  const formValueLanguage = language === COMMON_INPUT ? defaultLanguage : language;

  const onUpload = (file) => {
    if (language === COMMON_INPUT) {
      const ticket = languages.reduce((imgs, langKey) => {
        imgs[`ticket_image_${langKey}`] = file;
        return imgs;
      }, {});
      ticket.visual_type = get(formValues, 'ticket.visual_type');
      change('ticket', ticket);
    } else if (language === defaultLanguage) {
      const ticket = languages.reduce((imgs, langKey) => {
        const currentValue = get(formValues, `ticket.ticket_image_${langKey}`);
        imgs[`ticket_image_${langKey}`] = langKey === defaultLanguage ? file : currentValue || file;
        return imgs;
      }, {});
      ticket.visual_type = get(formValues, 'ticket.visual_type');
      change('ticket', ticket);
    } else {
      change(`ticket.ticket_image_${language}`, file);
    }
  };

  const renderVisualType = formValues.ticket.visual_type;

  return (
    <div className={cn(b())}>
      <div className={b('preview')}>
        <ImageField
          disabled={disabled}
          name={`ticket.ticket_image_${formValueLanguage}`}
          key={`ticket.ticket_image_${formValueLanguage}`}
          width={160}
          height={300}
          label={lang.TICKET}
          required={true}
          acceptFormats=".png,.jpg,.jpeg"
          placeholderIcon={placeholderIcon}
          onChange={onUpload}
          classNames={{
            controls: b('ticket-image-controls'),
          }}
          removable={false}
        />
        {renderVisualType === PRIZE_CODE_TYPES.QR_CODE && (
          <div className={b('code', [renderVisualType.replace('_', '-')])}>
            <Icon width={100} height={100} name="qr-code-wizz" className={b('code-icon')} />
            <div className={b('overlay')} />
          </div>
        )}
        {renderVisualType === PRIZE_CODE_TYPES.BAR_CODE && (
          <div className={b('code', [renderVisualType.replace('_', '-')])}>
            <Icon width={144} name="barcode-wizz" className={b('code-icon')} />
            <div className={b('overlay')} />
          </div>
        )}
        {renderVisualType === PRIZE_CODE_TYPES.PLAIN_TEXT && (
          <div className={b('code', [renderVisualType.replace('_', '-')])}>
            {lang.PLAIN_TEXT_PLACEHOLDER}
            <div className={b('overlay')} />
          </div>
        )}
      </div>

      <div className={b('column')}>
        <SelectField
          options={getTypeCodes(lang)}
          name="ticket.visual_type"
          label={lang.TYPE_OF_CODE_LABEL}
          disabled={disabled}
          required={true}
          simpleValue={true}
        />
      </div>
    </div>
  );
};

PrizeDiyTicketFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyTicketFieldset;
