import {dateToString} from 'client/services/helpers';

import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';
import {Coupon, Prize} from 'client/models/prizes/types';

const getValidityType = (coupon: Coupon | Prize) =>
  coupon.validity_duration ? PRIZE_COUPON_VALIDITY_TYPES.DURATION : PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE;

export default (coupon: Coupon | Prize | null) => {
  return {
    validity_type: coupon ? getValidityType(coupon) : PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE,
    validity_days: coupon?.validity_days?.toString(),
    valid_from: dateToString(coupon?.validity_from),
    valid_to: dateToString(coupon?.validity_to),
  };
};
