import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {DatepickerField, NumberField, SelectField} from 'client/common/fields';

import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';

import cssModule from './prize-diy-validity-fieldset.module.scss';

const b = bem('prize-diy-validity-fieldset', {cssModule});

type PrizeDiyValidityFieldsetProps = {
  prefix: string;
  validityType: string;
  disabled: boolean;
};

const PrizeDiyValidityFieldset: React.FC<PrizeDiyValidityFieldsetProps> = (props) => {
  const {prefix, validityType, disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_VALIDITY_FIELDSET');

  return (
    <div className={b()}>
      <SelectField
        className={b('type-field')}
        classNames={{control: () => b('input')}}
        name={`${prefix}.validity_type`}
        label={lang.VALIDITY_TYPE}
        options={[
          {value: PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE, label: lang.FIXED_VALUE},
          {value: PRIZE_COUPON_VALIDITY_TYPES.DURATION, label: lang.DURATION},
        ]}
        disabled={disabled}
        simpleValue
        required
      />
      {validityType === PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE ? (
        <>
          <DatepickerField
            className={b('date-field')}
            name={`${prefix}.valid_from`}
            label={lang.VALID_FROM}
            disabled={disabled}
            withWrap
            required
          />
          <DatepickerField
            className={b('date-field')}
            classNames={{overlay: b('to-field-overlay')}}
            name={`${prefix}.valid_to`}
            label={lang.VALID_TO}
            disabled={disabled}
            required
          />
        </>
      ) : (
        <NumberField
          className={b('number-field')}
          inputClassName={b('input')}
          name={`${prefix}.validity_days`}
          label={lang.VALIDITY_DAYS}
          disabled={disabled}
          required
        />
      )}
    </div>
  );
};

export default PrizeDiyValidityFieldset;
