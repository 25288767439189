import {Translation} from 'client/models/language/types';
import {Prize} from 'client/models/prizes/types';

import {WinParamsValues} from './types';

export default (prize: Prize | null, languages: string[]): WinParamsValues => {
  const defaultLanguage = languages[0];

  const getTranslations = (prefix: 'external_name' | 'external_description' | 'email_image' | 'legal' | 'picto') => {
    return languages.reduce((result: Record<string, Translation>, key) => {
      result[key] = (prize?.[`${prefix}_translations`]?.[key] || '') as Translation;
      return result;
    }, {});
  };

  return {
    language: defaultLanguage,
    defaultLanguage,
    langUniqueMode: prize?.language_unique_mode || false,
    name: getTranslations('external_name'),
    description: getTranslations('external_description'),
    image: getTranslations('email_image'),
    picto: getTranslations('picto'),
    legal: getTranslations('legal'),
  };
};
