import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';

export const isAnyLevelRequired = (values) => {
  const isDeviceLevelSet = values.geo_level;
  const isOnlineLevelSet = values.online_level !== SEGMENTATION_TYPES.NONE;
  const isDeviceOrOnlineLevelSet = isDeviceLevelSet || isOnlineLevelSet;

  return values.device_online_type && values.device_online_segmentation && !isDeviceOrOnlineLevelSet;
};

export default (values, {lang, autotaskTypes}) => {
  const errors = {};

  const numericOnlineStock = Number(values.online_stock);
  const numericInitialStock = Number(values.default_initial_stock);
  const numericDeviceStock = Number(values.device_stock);

  if (values.default_initial_stock && (!Number.isInteger(numericInitialStock) || numericInitialStock < 1)) {
    errors.default_initial_stock = lang.POSITIVE_INTEGER;
  }

  if (values.device_online_type && !values.device_online_segmentation && !values.default_initial_stock) {
    errors.default_initial_stock = lang.REQUIRED;
  }

  if (values.device_online_segmentation) {
    if (values.online_stock && (!Number.isInteger(numericOnlineStock) || numericOnlineStock < 1)) {
      errors.online_stock = lang.POSITIVE_INTEGER;
    }
    if (values.device_stock && (!Number.isInteger(numericDeviceStock) || numericDeviceStock < 1)) {
      errors.device_stock = lang.POSITIVE_INTEGER;
    }
    // when device and online segmentation toggle is on and no one level is set, at least one of the stocks should be filled
    if (isAnyLevelRequired(values) && !(values.device_stock || values.online_stock)) {
      errors.device_stock = lang.REQUIRED;
      errors.online_stock = lang.REQUIRED;
    }

    if (values.geo_level && !values.device_stock) {
      errors.device_stock = lang.REQUIRED;
    }

    if (values.online_level !== SEGMENTATION_TYPES.NONE && !values.online_stock) {
      errors.online_stock = lang.REQUIRED;
    }

    // when autotask is online or device only, the rendered field is required to fill
    // (only one field will be rendered. the error on non-rendered field is ignored by redux-form)
    if (!values.device_online_type) {
      errors.device_stock = values.device_stock || !autotaskTypes?.isDevicesType ? '' : lang.REQUIRED;
      errors.online_stock = values.online_stock || !autotaskTypes?.isOnlineType ? '' : lang.REQUIRED;
    }
  }

  return errors;
};
