import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {TextField, SelectField, TextareaField, NumberField} from 'client/common/fields';

import {LOYALTY_VALUE_UNITS, PRIZE_BARCODE_FORMAT, LOYALTY_COUPON_TYPES} from 'client/components/prizes/constants';
import PrizeDiyValidityFieldset from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset';
import PrizeDiyModal from 'client/components/prizes/modals/prize-diy-modal';
import {COUPON_TYPES} from 'client/models/prizes/constants';

import './prize-diy-coupon-fieldset.scss';

const b = bem('prize-diy-coupon-fieldset');

const PrizeDiyCouponFieldset = (props) => {
  const {disabled} = props;

  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_COUPONS_FIELDSET');
  const {formValues} = useReduxForm(PrizeDiyModal.formName);
  const client = useSelector(selectCurrentClient);

  const loyaltyCouponType = formValues.main.loyalty_coupon_type;

  return (
    <div className={b()}>
      <TextField
        name="coupon.internal_name"
        label={lang.INTERNAL_NAME}
        withWrap={true}
        disabled={disabled}
        maxLength={100}
        required={true}
      />
      <TextField
        name="coupon.internal_code"
        label={lang.INTERNAL_CODE}
        withWrap={true}
        disabled={disabled}
        required={true}
      />
      <TextField
        name="coupon.external_name"
        label={lang.EXTERNAL_NAME}
        withWrap={true}
        disabled={disabled}
        required={true}
      />
      {client.loyalty_program_id && (
        <div className={b('row')}>
          <NumberField
            name="coupon.loyalty_amount"
            label={lang.VALUE}
            withWrap={true}
            disabled={disabled}
            required={[LOYALTY_COUPON_TYPES.OPTIONAL, LOYALTY_COUPON_TYPES.MANDATORY].includes(loyaltyCouponType)}
          />
          <SelectField
            className={b('unit-field')}
            name="coupon.loyalty_unit"
            label={lang.UNIT}
            options={[
              {label: lang.POINTS, value: LOYALTY_VALUE_UNITS.POINTS},
              {label: lang.EUROS, value: LOYALTY_VALUE_UNITS.EURO},
            ]}
            simpleValue={true}
            withWrap={true}
            disabled={disabled}
            required={[LOYALTY_COUPON_TYPES.OPTIONAL, LOYALTY_COUPON_TYPES.MANDATORY].includes(loyaltyCouponType)}
          />
        </div>
      )}
      <SelectField
        name="coupon.type"
        label={lang.TYPE}
        options={[
          {label: lang.CLIENT_CODE, value: COUPON_TYPES.CLIENT},
          {label: lang.COMMON_CODE, value: COUPON_TYPES.COMMON},
        ]}
        simpleValue
        withWrap
        disabled={disabled}
      />
      <SelectField
        name="coupon.barcode_format"
        label={lang.BARCODE_TYPE}
        options={[
          {label: lang.BARCODE_NONE, value: PRIZE_BARCODE_FORMAT.NONE},
          {label: 'EAN-8', value: PRIZE_BARCODE_FORMAT.EAN_8},
          {label: 'EAN-13', value: PRIZE_BARCODE_FORMAT.EAN_13},
          {label: 'Code 128', value: PRIZE_BARCODE_FORMAT.CODE_128},
          {label: 'Code 39', value: PRIZE_BARCODE_FORMAT.CODE_39},
        ]}
        disabled={disabled}
        simpleValue
        withWrap
      />
      <PrizeDiyValidityFieldset prefix="coupon" validityType={formValues.coupon?.validity_type} disabled={disabled} />
      <TextareaField name="coupon.description" label={lang.DESCRIPTION} withWrap disabled={disabled} />
    </div>
  );
};

PrizeDiyCouponFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyCouponFieldset;
